<script>
  import DecideIcon from "../../components/sectionIcons/DecideIcon.svelte";

  import Triangle from "../../components/Triangle.svelte";
  import IsMobile from "../../utils/IsMobile.svelte";

  let isMobile;
</script>

<style lang="scss">
  @import "../../theme/bulma/theme.scss";

  .section {
    position: relative;
    width: 100%;
  }

  .section-background {
    position: absolute;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    top: 0;
    z-index: -1;
    background-color: #fff;
    @include mobile {
      display: none;
    }
  }

  .image-background {
    position: absolute;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    top: 0;
    z-index: -1;
    background-color: #fff;
    overflow: hidden;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100%;
    max-width: 600px;
  }

  .image-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5rem;

    &.mobile {
      margin: {
        bottom: 3rem;
        left: 0;
      }

      .image {
        margin-right: 0;
      }
    }
  }

  .image {
    max-height: 60rem;
    margin-right: 10rem;
  }

  hr {
    background-color: none;
    border-top: 2px dotted $blue;
    opacity: 0.5;
  }

  p.quote {
    color: $grey;
    font-size: 0.9em;
  }

  p {
    color: $paragraph-color;
    font-size: 1.1em;
    @include mobile {
      text-align: center;
    }
  }

  strong {
    color: $paragraph-color;
  }

  .title {
    margin-top: 0 !important;
  }
</style>

<section class="section">
  <div class="section-foreground">
    <div class="columns">
      <div
        class="column is-8 rellax image-parent-container"
        data-rellax-speed="2.5"
        data-rellax-percentage="0.5">
        {#if !isMobile}
          <div class="image-container">
            <img class="image" src="img/screens/decide.png" alt="" />
          </div>
        {/if}
      </div>
      <div class="column">
        <div class="content">
          {#if isMobile}
            <div class="image-container mobile">
              <img class="image" src="img/screens/decide.png" alt="" />
              <div class="image-background">
                <Triangle
                  class="rellax"
                  percentage="0"
                  speed="-2"
                  positionX="-60vw"
                  positionY="20%"
                  fill="#89ffbd"
                  rotation="-55"
                  width="30vw" />

                <Triangle
                  class="rellax"
                  percentage="0"
                  speed="-1"
                  positionX="-40vw"
                  positionY="30%"
                  fill="#53ebff"
                  rotation="-30"
                  width="30vw" />
              </div>
            </div>
          {/if}

          <div class="section-icon">
            <DecideIcon />
          </div>
          <h2 class="title">Decide</h2>
          <p>
            Vote on ideas, anonymously. It's easy to make decisions when the
            whole team has a say in what ideas they love and why.
          </p>

          <hr />
          <p class="quote">
            <em>
              "As every team has a mixture of both introverts and extroverts,
              decision-making can sometimes be one-sided. Creation Station
              enables a team to anonymously collect ideas and decisions which
              ensures that everyone in the team can voice their truth, without
              feeling singled out."
              <p><br>
                <strong>Product Manager</strong>
                </p>
            </em>
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="section-background">
    <Triangle
      class="rellax"
      percentage="0"
      speed="-2"
      positionX="-60vw"
      positionY="20%"
      fill="#FFA379"
      rotation="-55"
      width="30vw" />

    <Triangle
      class="rellax"
      percentage="0"
      speed="-1"
      positionX="-40vw"
      positionY="30%"
      fill="#79B9FF"
      rotation="-30"
      width="30vw" />
  </div>
</section>

<IsMobile bind:isMobile />
