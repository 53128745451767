<script>
  import { Router } from "@roxi/routify";
  import { routes } from "../.routify/routes";
</script>

<style lang="scss" global>
  @import "./theme/global.scss";
</style>

<Router routes="{routes}" />
