<script>
  import IsMobile from "../../utils/IsMobile.svelte";

  let isMobile;
</script>

<style lang="scss">
  @import "../../theme/bulma/theme.scss";

  .section {
    position: relative;
    width: 100%;
  }

  .section-background {
    position: absolute;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    top: 0;
    z-index: -1;
    background-color: #fff;
    @include mobile {
      display: none;
    }
  }

  .image-background {
    position: absolute;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100vw;
    top: 0;
    z-index: -1;
    background-color: #fff;
    overflow: hidden;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100%;
    max-width: 600px;
  }

  .image-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5rem;

    &.mobile {
      margin: {
        bottom: 3rem;
        left: 0;
      }

      .image {
        margin-right: 0;
      }
    }
  }

  .image {
    max-height: 60rem;
    margin-right: 10rem;
  }

  hr {
    background-color: none;
    border-top: 2px dotted $orange;
    opacity: 0.5;
  }

  p.quote {
    color: $grey;
    font-size: 0.9em;
  }

  p {
    color: $paragraph-color;
    font-size: 1.1em;
    @include mobile {
      text-align: center;
    }
  }

  strong {
    color: $paragraph-color;
  }

  .title {
    margin-top: 0 !important;
  }
</style>

<section class="section">
  <div class="section-foreground">
    <div class="columns">
      <div
        class="column is-8 rellax image-parent-container"
        data-rellax-speed="2.5"
        data-rellax-percentage="0.5">
        {#if !isMobile}
          <div class="image-container">
            <img class="image" src="img/cocreate.svg" alt="" />
          </div>
        {/if}
      </div>
      <div class="column">
        <div class="content">
          {#if isMobile}
            <div class="image-container mobile">
              <img class="image" src="img/cocreate.svg" alt="" />
            </div>
          {/if}

          <h2 class="title">Co-create</h2>
          <p>
            The journey from decisions to ideas is simple with Creation Station.
            Restructure organisational memory so that it is easy to trace how
            decisions were made and what ideas were left on the drawing board.
          </p>

          <hr />
          <p class="quote">
            <em>
              "Co-creation is easy with Creation Station, no matter if you are
              in an office or working remotely. Go from ideas to decisions in 15
              minutes!"
              <p><br>
              <strong>Strategist & Researcher</strong>
              </p>
            </em>
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<IsMobile bind:isMobile />
