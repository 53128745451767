<script>
  import { onMount } from "svelte";
  import gsap from "gsap";

  let svg;
  let classes = "";

  export let positionX = 0;
  export let positionY = 0;
  export let fill;
  export let rotation = 0;
  export let height = "100%";
  export let width = "15%";
  export let speed = "-2";
  export let percentage = "0.5";

  export { classes as class };

  onMount(() => {
    gsap.set(svg, {
      rotate: rotation,
      x: positionX,
      y: positionY,
    });
  });
</script>

<style>
  svg {
    position: absolute;
  }
</style>

<svg
  class="{classes}"
  width="{width}"
  height="{height}"
  viewBox="0 0 100 100"
  bind:this="{svg}"
  data-rellax-percentage="{percentage}"
  data-rellax-speed="{speed}">
  <polygon points="0,0 0,100 100,100" fill="{fill}"></polygon>
</svg>
