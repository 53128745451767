<script>
  import IsMobile from "../../utils/IsMobile.svelte";

  const year = new Date().getFullYear();

  // import { fbButton, tw, linkedin, email } from "vanilla-sharing";

  // import {
  //   Email,
  //   LinkedIn,
  //   Facebook,
  //   Twitter,
  // } from "../../components/svelte-share-buttons-component/src";
  import SocialsFooter from "../../components/SocialsFooter.svelte";

  const url = "https://creationstation.io";

  // const handleLinkedin = () => {
  //   linkedin({
  //     url,
  //   });
  // };

  // const handleTwitter = () => {
  //   tw({
  //     url,
  //   });
  // };

  // const handleFacebook = () => {
  //   fbButton({
  //     url,
  //   });
  // };

  // const handleEmail = () => {
  //   email({
  //     url,
  //   });
  // };

  let isMobile;
</script>

<style lang="scss">
  @import "../../theme/bulma/theme.scss";

  .logo {
    padding: {
      right: 3em;
    }
    margin: {
      bottom: 2em;
    }
    height: 64px;
  }

  .links {
    display: flex;
    flex-direction: column;
  }

  .link {
    margin: {
      right: 2em;
    }
    & > * {
      color: $footer-link-color;
    }
  }

  .section.subfooter {
    background-color: #fff;
    color: rgb(0, 0, 0, 0.3);
    margin: {
      left: 2em;
      right: 2em;
    }

    a {
      color: rgb(0, 0, 0, 0.3);
    }
  }

  * {
    font-family: $family-secondary;
  }

  .button.is-success {
    color: $paragraph-color;
    border-radius: 3px;
  }

  .centered {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 0;
    &.logo {
      padding: 0.75em;
    }
  }

  .footer {
    @include touch {
      padding: {
        top: 6rem;
        left: 6rem;
        right: 6rem;
      }
    }
    @include mobile {
      padding: {
        left: 0;
        right: 0;
      }
    }
  }

  .questions-column {
    min-width: 260px;
  }

  .button {
    border: none;
    line-height: 19.07px;

    &.is-large {
      font-size: 14px;
      padding: 1.5rem;
    }

    &.green {
      background-color: $green;
    }
    &.purple {
      background-color: $purple;
      color: #fff;
    }
  }
</style>

<footer class="footer">
  <div class="container">
    <div class="columns">
      <div class="column is-6">
        <div class="columns">
          <div class="column is-narrow logo" class:centered="{isMobile}">
            <img class="image is-64x64" src="img/logo-black.svg" alt="" />
          </div>
          <div class="column questions-column" class:centered="{isMobile}">
            <h5 class="title is-5">Questions? Get in touch.</h5>
            <div class="columns links">
              <div class="column link" class:centered="{isMobile}">
                <a href="mailto:hello@creationstation.io">
                  hello@creationstation.io
                </a>
              </div>
              <!-- <div class="column link" class:centered="{isMobile}">
                <a
                  href="https://app.creationstation.io/privacy-policy"
                  target="_blank">
                  <u>Privacy Policy</u>
                </a>
              </div>
              <div class="column link" class:centered="{isMobile}">
                <a
                  href="https://app.creationstation.io/terms-of-service"
                  target="_blank">
                  <u>Terms of Service</u>
                </a>
              </div> -->
            </div>
          </div>
        </div>
      </div>

      <!-- Gap -->
      <div class="column"></div>

      <div class="column is-narrow" class:centered="{isMobile}">
        <h5 class="title is-5">Share the love</h5>

        <SocialsFooter />

        <!-- <div class="columns is-desktop">
          <div
            class="column link"
            class:centered="{isMobile}"
            on:click="{handleLinkedin}">
            <span>LinkedIn</span>
          </div>
          <div
            class="column link"
            class:centered="{isMobile}"
            on:click="{handleTwitter}">
            <span>Twitter</span>
          </div>
          <div
            class="column link"
            class:centered="{isMobile}"
            on:click="{handleFacebook}">
            <span>Facebook</span>
          </div>
          <div
            class="column link"
            class:centered="{isMobile}"
            on:click="{handleEmail}">
            <span>Email</span>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</footer>

<div class="section subfooter">
  <div class="container">
    <div class="columns">
      <div class="column" class:centered="{isMobile}">
        <span> &copy; {year} ALSKA DIGITAL PTY LTD. All rights reserved.</span>

        <span>&mdash;</span>

        <a href="/privacy-policy" target="_blank">
          <strong>
            <u>Privacy Policy</u>
          </strong>
        </a>

        <span>&mdash;</span>

        <a href="/terms-of-service" target="_blank">
          <strong>
            <u>Terms of Service</u>
          </strong>
        </a>
      </div>
      {#if !isMobile}
        <div class="column is-narrow">
          <!-- <button
            class="button is-success shadow-lightest"
            on:click="{() => {
              localStorage.setItem('visited', false);
              location.reload();
            }}">
            Replay intro animation
          </button> -->

          <button
            class="button is-large green"
            on:click="{() => {
              localStorage.setItem('visited', false);
              location.reload();
            }}"><strong>Replay intro animation</strong></button>
        </div>
      {/if}
    </div>
  </div>
</div>

<IsMobile bind:isMobile />
