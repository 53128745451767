<script>
  import IsMobile from "../../../utils/IsMobile.svelte";

  let isMobile;
</script>

<style lang="scss">
  @import "../../../theme/bulma/theme.scss";

  .subtitle-container {
    margin: {
      top: 3em;
    }
  }

  .subtitle {
    font-size: 1em;
    text-align: center;
    font-family: $family-secondary;
    color: $grey;
    & > * {
      color: $grey;
    }
  }
</style>

<div class="subtitle-container rellax" data-rellax-speed="1">
  <p class="subtitle">
    <strong>Creation Station</strong>
    is your complete
    {#if isMobile}
      <br />
    {/if}
    Design Thinking workstation.
  </p>
</div>

<IsMobile bind:isMobile />
