<script>
  import { fade } from "svelte/transition";

  import Triangle from "../../components/Triangle.svelte";
  import PartnerText from "./components/PartnerText.svelte";
  import PartnerSubtext from "./components/PartnerSubtext.svelte";

  // let showCalendly = false;
  let y = 0;

  // const handleOpenCalendly = () => {
  //   showCalendly = true;
  // };
</script>

<style lang="scss">
  @import "../../theme/bulma/theme.scss";

  .partner-container {
    position: relative;
    padding: {
      top: 15em;
      bottom: 15em;
    }
    overflow: hidden;
    min-height: 0;
    transition: min-height 0.3s ease;
  }

  .partner-background-container {
    position: absolute;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    top: 0;
    z-index: -1;
    @include touch {
      display: none;
    }
  }

  .partner-mobile-triangles {
    position: relative;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 0;
    width: 100%;
    top: 0;
    z-index: -1;
    @include desktop {
      display: none;
    }
  }

  .partner-foreground-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .form-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .partner-background-wrapper {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    z-index: -1;
  }

  .button {
    border: {
      radius: 3px;
    }
  }
</style>

<div class="partner-container" id="partner-program">
  <div
    class="partner-background-wrapper"
    style="transform: translate3d(0px, {y / 2}px, 0px);">
    <div
      class="partner-background-container rellax"
      data-rellax-percentage="0.5">
      <Triangle
        class="rellax"
        percentage="0"
        speed="-4"
        positionX="-35vw"
        positionY="17em"
        width="25em"
        fill="#53ebff"
        rotation="60" />

      <Triangle
        class="rellax"
        percentage="0"
        speed="-3"
        positionX="-30vw"
        positionY="17em"
        width="25em"
        fill="#ff9e71"
        rotation="80" />

      <Triangle
        class="rellax"
        percentage="0"
        speed="-2"
        positionX="-25vw"
        positionY="18em"
        width="25em"
        fill="#FFE35E"
        rotation="100" />

      <Triangle
        class="rellax"
        percentage="0"
        speed="-1"
        positionX="-20vw"
        positionY="22em"
        width="25em"
        fill="#63BAFF"
        rotation="120" />

      <Triangle
        class="rellax"
        percentage="0"
        speed="0"
        positionX="-15vw"
        positionY="29em"
        width="25em"
        fill="#C373FF"
        rotation="140" />
    </div>
  </div>

  <div
    class="partner-foreground-container rellax"
    data-rellax-percentage="0.5"
    data-rellax-speed="2">
    <PartnerText />
    <PartnerSubtext />

    <!-- {#if showCalendly} -->
    <div transition:fade class="form-container" bind:clientHeight="{y}">
      <!-- <PartnerForm /> -->
      <a
        class="button is-large is-dark"
        href="https://calendly.com/alska-digital/meet-greet"
        target="_blank"><strong>Schedule Meeting</strong></a>
    </div>
    <!-- {:else} -->
    <!-- <PartnerButton
        on:click="{handleOpenCalendly}"
        buttonText="Become a Partner" /> -->
    <!-- <button
        class="button is-large is-primary"
        on:click="{handleOpenCalendly}">
        <strong> Become a Partner </strong>
      </button> -->
    <!-- {/if} -->

    <div class="partner-mobile-triangles">
      <Triangle
        class="rellax"
        percentage="0"
        speed="-4"
        positionX="-20vw"
        positionY="18em"
        width="15em"
        fill="#53ebff"
        rotation="60" />

      <Triangle
        class="rellax"
        percentage="0"
        speed="-3"
        positionX="-10vw"
        positionY="16em"
        width="15em"
        fill="#ff9e71"
        rotation="80" />

      <Triangle
        class="rellax"
        percentage="0"
        speed="-2"
        positionX="0vw"
        positionY="16em"
        width="15em"
        fill="#FFE35E"
        rotation="100" />

      <Triangle
        class="rellax"
        percentage="0"
        speed="-1"
        positionX="10vw"
        positionY="18em"
        width="15em"
        fill="#63BAFF"
        rotation="120" />

      <Triangle
        class="rellax"
        percentage="0"
        speed="0"
        positionX="18vw"
        positionY="21em"
        width="15em"
        fill="#C373FF"
        rotation="140" />
    </div>
  </div>
</div>
