<script>
  import ResultsIcon from "../../components/sectionIcons/ResultsIcon.svelte";

  import Triangle from "../../components/Triangle.svelte";
  import IsMobile from "../../utils/IsMobile.svelte";

  let isMobile;
</script>

<style lang="scss">
  @import "../../theme/bulma/theme.scss";

  .section {
    position: relative;
    width: 100%;
    overflow: hidden;
  }

  .section-background {
    position: absolute;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    top: 0;
    z-index: -1;
    background-color: $footer-background-color;
    @include mobile {
      display: none;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100%;
    max-width: 600px;
  }

  .image-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5rem;

    &.mobile {
      margin: {
        bottom: 3rem;
        right: 0;
      }

      .image {
        margin-left: 0;
      }
    }
  }

  .image {
    max-height: 60rem;
    margin-left: 10rem;
  }

  hr {
    background-color: none;
    border-top: 2px dotted #69d197;
    opacity: 0.5;
  }

  p.quote {
    color: $grey;
    font-size: 0.9em;
  }

  p {
    color: $paragraph-color;
    @include mobile {
      text-align: center;
    }
  }

  strong {
    color: $paragraph-color;
  }

  .image-parent-container {
    padding: 0;
  }

  .title {
    margin-top: 0 !important;
  }

  .justify-end {
    display: flex;
    justify-content: flex-end;
  }
</style>

<section class="section">
  <div class="section-foreground">
    <div class="columns">
      <div class="column justify-end">
        <div class="content">
          {#if isMobile}
            <div class="image-container mobile">
              <img class="image" src="img/screens/results.png" alt="" />
            </div>
          {/if}

          <div class="section-icon">
            <ResultsIcon />
          </div>
          <h2 class="title">Results</h2>
          <p>
            Creation Station makes ideation anonymous; the focus is always on
            the ideas. View the results of the activity and see which ideas were
            most popular in each category.
          </p>

          <hr />
          <p class="quote">
            <em>
              "In all my experience with the Design Thinking process for
              different projects, the common thread in all these projects are
              the wasted ideas. The innovation process is extremely demanding
              with different stages, and sometimes the ideas that pop up during
              the process are lost due to the ideate phase in the future.
              Creation Station brings the 'ideation act' for all processes and
              is transversal across the whole journey - this means that you can
              share new ideas and save them at any time to be discussed and
              voted at the right time, which can be extremely valuable."
              <p><br>
                <strong>Product Designer</strong>
                </p>
            </em>
        </div>
      </div>
      <div
        class="column is-8 rellax image-parent-container"
        data-rellax-speed="2.5"
        data-rellax-percentage="0.5">
        {#if !isMobile}
          <div class="image-container">
            <img class="image" src="img/screens/results.png" alt="" />
          </div>
        {/if}
      </div>
    </div>
  </div>

  <div class="section-background">
    <Triangle
      class="rellax"
      percentage="0"
      speed="-2"
      positionX="55vw"
      positionY="40%"
      fill="#53ebff"
      rotation="100"
      width="30vw" />

    <Triangle
      class="rellax"
      percentage="0"
      speed="-1"
      positionX="40vw"
      positionY="70%"
      fill="#89ffbd"
      rotation="125"
      width="30vw" />
  </div>
</section>

<IsMobile bind:isMobile />
