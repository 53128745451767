
/**
 * @roxi/routify 2.15.0
 * File generated Tue May 21 2024 02:48:02 GMT+0000 (Coordinated Universal Time)
 */

export const __version = "2.15.0"
export const __timestamp = "2024-05-21T02:48:02.451Z"

//buildRoutes
import { buildClientTree } from "@roxi/routify/runtime/buildRoutes"

//imports
import _index from '../src/pages/index.svelte'
import _privacyPolicy from '../src/pages/privacy-policy.svx'
import _termsOfService from '../src/pages/terms-of-service.svx'

//options
export const options = {}

//tree
export const _tree = {
  "root": true,
  "children": [
    {
      "isIndex": true,
      "isPage": true,
      "path": "/index",
      "id": "_index",
      "component": () => _index
    },
    {
      "ext": "svx",
      "isPage": true,
      "path": "/privacy-policy",
      "id": "_privacyPolicy",
      "component": () => _privacyPolicy
    },
    {
      "ext": "svx",
      "isPage": true,
      "path": "/terms-of-service",
      "id": "_termsOfService",
      "component": () => _termsOfService
    }
  ],
  "path": "/"
}


export const {tree, routes} = buildClientTree(_tree)

