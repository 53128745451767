<script>
  let w;
  export let isMobile = false;
  export let isTouch = false;

  $: w <= 768 ? (isMobile = true) : (isMobile = false);
  $: w <= 1023 ? (isTouch = true) : (isTouch = false);
</script>

<svelte:window bind:innerWidth="{w}" />
