<script>
  import { slide } from "svelte/transition";

  import HeaderText from "./HeaderText.svelte";
  import HeaderSubtext from "./HeaderSubtext.svelte";
  // import Nav from "../../01-nav/Nav.svelte";

  import IsMobile from "../../../utils/IsMobile.svelte";
  import Logo from "../../../components/Logo.svelte";
  import { scrollTo } from "svelte-scrollto";

  let isMobile;
  // let isSuccess;
  // let isSuccessMobile;
  let showSessionInput = false;
  let sessionInputValue = "";
  let invalidSessionInputValue = false;

  const re = /\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/;

  const scrollToTrySection = () => {
    const tryDOM = document.getElementById("try-app");

    // Put element in center of screen
    const offset = -window.innerHeight / 2 + tryDOM.clientHeight / 2;

    scrollTo({ element: tryDOM, duration: 3000, offset });
  };

  const handleJoinSession = () => {
    showSessionInput = true;
  };

  const handleJoinSessionSubmit = () => {
    // Regex input and find ID
    const ids = re.exec(sessionInputValue);

    if (ids) {
      window.location = "https://app.creationstation.io/activity/" + ids[0];
    } else {
      invalidSessionInputValue = true;
    }
  };
</script>

<style lang="scss">
  @import "../../../theme/bulma/theme.scss";

  .header-hero-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .header-foreground-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    height: 100vh;
    width: 100%;
    padding: {
      top: 120px;
      bottom: 120px;
    }
    & > * {
      min-height: 0;
    }
  }

  .logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4.75rem 1rem;
  }

  .app-buttons {
    margin-top: 3rem;
    margin-bottom: 1rem;
  }

  .button {
    border: none;
    line-height: 19.07px;

    &.is-large {
      font-size: 14px;
      padding: 1.5rem;
    }

    &.green {
      background-color: $green;
    }
    &.purple {
      background-color: $purple;
      color: #fff;
    }
  }

  p.sign-up {
    color: #000;
    font-weight: 600;
    margin: {
      left: 2rem;
      right: 2rem;
      top: 0.5rem;
    }

    a {
      color: #000;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .session-form {
    padding: 2rem;
    min-width: 50%;

    input.input.session-input {
      height: 100%;
      box-shadow: inset 0 0 0.125em rgba($dark, 0.2);
    }
  }

  .logo-container {
    height: 15vh;
    width: 80%;
    padding: 0;
    padding-top: 6vh;
  }
</style>

<section class="header-hero-container">
  <div class="logo-container">
    <Logo />
  </div>

  <!-- Foreground -->
  <header class="header-foreground-container">
    <HeaderText />
    <div class="subtext">
      <HeaderSubtext />
    </div>
    {#if !showSessionInput}
      <div transition:slide class="buttons app-buttons">
        <!-- Create new session -->
        <button class="button is-large green" on:click="{scrollToTrySection}"
          ><strong>Create session</strong></button>

        <!-- Join a session -->
        <button class="button is-large purple" on:click="{handleJoinSession}"
          ><strong>Join session</strong></button>
      </div>
    {/if}

    {#if showSessionInput}
      <div transition:slide class="session-form">
        <div class="field has-addons">
          <p class="control is-expanded">
            <input
              class="input session-input"
              type="text"
              placeholder="URL or ID: e.g. 12345678-1234-1a2b-3c4d-abcdef12a1234"
              bind:value="{sessionInputValue}" />
          </p>
          <p class="control">
            <button
              class="button is-primary is-large"
              on:click="{handleJoinSessionSubmit}">
              <strong>Open Session</strong>
            </button>
          </p>
        </div>
        {#if invalidSessionInputValue}
          <p transition:slide style="text-align: center; font-size: 0.8rem;">
            <em>Oops, we couldn't find a valid Creation Station session ID.</em>
          </p>
        {/if}
      </div>
    {/if}

    <p class="sign-up">
      Keen to check it out early? <a href="https://app.creationstation.io"
        >Click here to access the BETA.</a>
    </p>
  </header>

  <!-- Background -->
  <slot />
</section>

<IsMobile bind:isMobile />
