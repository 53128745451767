<script>
  import { onMount } from "svelte";
  import Rellax from "rellax";

  // import Nav from "./01-nav/Nav.svelte";
  import Header from "./02-header/Header.svelte";
  // import Video from "./03-video/Video.svelte";
  import MainContent from "./04-long-content/MainContent.svelte";
  import Try from "./05-call-to-action/Try.svelte";
  import PartnerProgram from "./05-call-to-action/PartnerProgram.svelte";
  import Footer from "./06-footer/Footer.svelte";

  import IsMobile from "../utils/IsMobile.svelte";

  let rellax;
  let h;
  let isTouch;

  $: if (isTouch === false) {
    // Fixes parallax when moving from mobile to higher screen widths, where new non-mobile rellax DOM elements become mounted by Svelte.
    rellax.destroy();
    rellax = new Rellax(".rellax");
  }

  $: if (isTouch === true) {
    requestAnimationFrame(() => setTimeout(() => rellax.destroy()));
  }

  $: if (h && isTouch === false) {
    // As new elements are mounted to DOM, height will change -> trigger parallax refresh
    requestAnimationFrame(() => setTimeout(() => rellax.refresh()));
  }

  onMount(() => {
    rellax = new Rellax(".rellax");
  });
</script>

<!-- <Nav /> -->
<Header />

<div class="centered-rellax-content" bind:clientHeight="{h}">
  <!-- <Video /> -->
  <MainContent />
  <Try />
  <PartnerProgram />
  <Footer />
</div>

<IsMobile bind:isTouch />
