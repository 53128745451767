<script>
  import IntroContent from "./IntroContent.svelte";
  import Generate from "./Generate.svelte";
  import Analyse from "./Analyse.svelte";
  import Decide from "./Decide.svelte";
  import Results from "./Results.svelte";
  import Cocreate from "./Cocreate.svelte";

  let clientHeight;
</script>

<style lang="scss">
  @import "../../theme/bulma/theme.scss";

  .main-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
  }
</style>

<section class="main-content">
  <!-- Intro section -->
  <IntroContent />

  <!-- Generate -->
  <Generate bind:clientHeight />

  <!-- Analyse -->
  <Analyse />

  <!-- Decide -->
  <Decide />

  <!-- Results -->
  <Results />

  <!-- Co-create -->
  <Cocreate clientHeight="{clientHeight}" />
</section>
