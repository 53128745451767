<script>
  import AnalyseIcon from "../../components/sectionIcons/AnalyseIcon.svelte";

  import Triangle from "../../components/Triangle.svelte";
  import IsMobile from "../../utils/IsMobile.svelte";

  let isMobile;
</script>

<style lang="scss">
  @import "../../theme/bulma/theme.scss";

  .section {
    position: relative;
    width: 100%;
  }

  .section-background {
    position: absolute;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    top: 0;
    z-index: -1;
    background-color: $footer-background-color;
    @include mobile {
      display: none;
    }
  }

  .image-background {
    position: absolute;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    top: 0;
    z-index: -1;
    background-color: $footer-background-color;
    overflow: hidden;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100%;
    max-width: 600px;
  }

  .image-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5rem;

    &.mobile {
      margin: {
        bottom: 3rem;
        right: 0;
      }

      .image {
        margin-left: 0;
      }
    }
  }

  .image {
    max-height: 60rem;
    margin-left: 10rem;
  }

  hr {
    background-color: none;
    border-top: 2px dotted $purple;
    opacity: 0.5;
  }

  p.quote {
    color: $grey;
    font-size: 0.9em;
  }

  p {
    color: $paragraph-color;
    font-size: 1.1em;
    @include mobile {
      text-align: center;
    }
  }

  strong {
    color: $paragraph-color;
  }

  .mobile-background {
    position: absolute;
    pointer-events: none;
    height: 100%;
    width: 100%;
    top: 0;
    z-index: -99;
    background-color: $footer-background-color;
  }

  .title {
    margin-top: 0 !important;
  }

  .justify-end {
    display: flex;
    justify-content: flex-end;
  }
</style>

<section class="section">
  <div class="section-foreground">
    <div class="columns">
      <div class="column justify-end">
        <div class="content">
          {#if isMobile}
            <div class="image-container mobile">
              <img class="image" src="img/screens/analyse.png" alt="" />
              <div class="image-background">
                <Triangle
                  class="rellax"
                  percentage="0"
                  speed="-2"
                  positionX="60vw"
                  positionY="-25%"
                  fill="#fee274"
                  rotation="-55"
                  width="30vw" />

                <Triangle
                  class="rellax"
                  percentage="0"
                  speed="-1"
                  positionX="30vw"
                  positionY="30%"
                  fill="#78b8ff"
                  rotation="-80"
                  width="60%" />
              </div>
            </div>
          {/if}

          <div class="section-icon">
            <AnalyseIcon />
          </div>
          <h2 class="title">Analyse</h2>
          <p>
            Analyse the data sets you collected in one place. Collaborate in
            real-time to code and categorise data and discover themes. Creation
            Station makes it effortless to visualise the patterns in large
            volumes of information.
          </p>

          <hr />
          <p class="quote">
            <em>
              "I find large qualitative data sets rather overwhelming. But with
              this it's so easy to work together to quickly organise data sets
              into categories and themes. And since it's digital, this organised
              data can be used for multiple activities after that. I keep
              talking about 'data flow' since Creation Station is the first
              digital research tool I've seen that actually treats data
              dynamically - as something 'in-flow'."
              <p><br>
                <strong>App Developer</strong>
                </p>
            </em>
          </p>
          
        </div>
      </div>
      <div
        class="column is-8 rellax image-parent-container"
        data-rellax-speed="2.5"
        data-rellax-percentage="0.5">
        {#if !isMobile}
          <div class="image-container">
            <img class="image" src="img/screens/analyse.png" alt="" />
          </div>
        {/if}
      </div>
    </div>
  </div>

  <div class="section-background">
    <!-- <Triangle
      class="rellax"
      percentage="0"
      speed="-2"
      positionX="60vw"
      positionY="-25%"
      fill="#fee274"
      rotation="-55"
      width="30vw" />

    <Triangle
      class="rellax"
      percentage="0"
      speed="-1"
      positionX="30vw"
      positionY="30%"
      fill="#78b8ff"
      rotation="-80"
      width="60%" /> -->

    <Triangle
      class="rellax"
      percentage="0"
      speed="-2"
      positionX="60vw"
      positionY="10%"
      fill="#FFE374"
      rotation="60"
      width="30vw" />

    <Triangle
      class="rellax"
      percentage="0"
      speed="-1"
      positionX="50vw"
      positionY="70%"
      fill="#B879FF"
      rotation="120"
      width="30vw" />
  </div>

  <div class="mobile-background"></div>
</section>

<IsMobile bind:isMobile />
