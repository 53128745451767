<script>
  import Triangle from "../../../components/Triangle.svelte";
  import IsMobile from "../../../utils/IsMobile.svelte";

  let w, h;
  let isMobile;

  $: landscape = w > h;
</script>

<style lang="scss">
  .header-background-container {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    top: 0;
    z-index: -1;
    overflow: hidden;
  }
</style>

<div class="header-background-container rellax" data-rellax-speed="-2">
  <Triangle
    positionX="-50vw"
    fill="#88ffbd"
    width="{isMobile ? '15%' : '30%'}"
    rotation="225" />
</div>

<div class="header-background-container rellax" data-rellax-speed="-2">
  <Triangle
    positionX="50vw"
    fill="#b879fe"
    width="{isMobile ? '15%' : '30%'}"
    rotation="45" />
</div>

<div class="header-background-container rellax" data-rellax-speed="-2">
  {#if landscape}
    <Triangle
      positionY="50vh"
      fill="#53ebff"
      width="25%"
      height="30%"
      rotation="135" />
  {:else}
    <Triangle
      positionY="50vh"
      fill="#53ebff"
      height="25%"
      width="30%"
      rotation="135" />
  {/if}
</div>

<svelte:window bind:innerWidth="{w}" bind:innerHeight="{h}" />

<IsMobile bind:isMobile />
