<script>
  export let width = "100%";
  export let height = "100%";
</script>

<svg
  width="{width}"
  height="{height}"
  viewBox="0 0 351 53"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  preserveAspectRatio="xMidYMid meet">
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M53.5151 35.4644L36.7612 52.2472L36.8 18.7471L53.5151 35.4644Z"
    fill="#50FFB7"></path>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M17.7026 52.7632L34.3406 35.9684L0.999512 35.9294L17.7026 52.7632Z"
    fill="#00EFFF"></path>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M19.2742 52.9998H35.4206V36.7764L19.2742 52.9998Z"
    fill="#63BAFF"></path>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M1.80688 17.6475L18.5603 0.864746L18.5215 34.3648L1.80688 17.6475Z"
    fill="#C373FF"></path>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M20.0596 0V16.2234L36.2055 0H20.0596Z"
    fill="#FFE35E"></path>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M37.5459 0.622314L54.249 17.4556L20.9075 17.4167L37.5459 0.622314Z"
    fill="#FF9E70"></path>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M0.899902 34.815H17.0458L0.899902 18.592V34.815Z"
    fill="#63BAFF"></path>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M313.549 19.8877C313.781 19.8877 313.959 19.8385 314.084 19.7411C314.21 19.6437 314.272 19.5064 314.272 19.3274C314.272 19.1488 314.207 19.0125 314.075 18.9183C313.943 18.8242 313.768 18.7769 313.549 18.7769H312.673V19.8877H313.549ZM313.825 18.1985C314.18 18.1985 314.469 18.2969 314.692 18.4926C314.914 18.6892 315.025 18.9448 315.025 19.2606C315.025 19.563 314.928 19.8176 314.734 20.0217C314.54 20.2258 314.282 20.3399 313.959 20.3658V20.3849C314.098 20.4331 314.226 20.5133 314.342 20.6265C314.457 20.7397 314.617 20.9697 314.82 21.3175L315.277 22.0916H314.401L314.22 21.7475C314.064 21.4293 313.918 21.1719 313.78 20.9753C313.642 20.7791 313.522 20.6474 313.421 20.5783C313.319 20.5091 313.178 20.4758 312.997 20.4758H312.673V22.0916H311.964V18.1985H313.825ZM310.521 20.1113C310.521 20.6535 310.655 21.1544 310.923 21.6131C311.192 22.0727 311.551 22.4326 312.001 22.6942C312.452 22.9558 312.943 23.0866 313.473 23.0866C314.009 23.0866 314.507 22.9525 314.967 22.6849C315.427 22.4168 315.79 22.0546 316.053 21.5996C316.316 21.1433 316.447 20.647 316.447 20.1113C316.447 19.5826 316.314 19.0877 316.048 18.6295C315.782 18.1699 315.42 17.8068 314.963 17.5405C314.505 17.2748 314.009 17.1412 313.473 17.1412C312.946 17.1412 312.455 17.2729 311.999 17.5354C311.543 17.7993 311.184 18.1625 310.919 18.6263C310.654 19.091 310.521 19.5859 310.521 20.1113ZM309.854 20.1115C309.854 19.4645 310.014 18.8621 310.33 18.3037C310.648 17.7462 311.09 17.3014 311.657 16.9717C312.223 16.6419 312.832 16.4768 313.482 16.4768C314.145 16.4768 314.756 16.6401 315.315 16.9675C315.873 17.294 316.311 17.7397 316.626 18.3037C316.942 18.8681 317.1 19.471 317.1 20.1115C317.1 20.7813 316.937 21.3967 316.612 21.9574C316.287 22.5195 315.845 22.9592 315.286 23.2788C314.728 23.5965 314.126 23.7569 313.482 23.7569C312.832 23.7569 312.223 23.5904 311.657 23.2588C311.09 22.9277 310.648 22.4852 310.33 21.9315C310.014 21.3786 309.854 20.772 309.854 20.1115Z"
    fill="#2B3137"></path>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M78.7582 15.613C80.0488 15.613 81.3126 15.7601 82.5492 16.0546C83.7858 16.3486 85.1193 16.85 86.5512 17.5582V21.7105C85.1521 20.9044 83.8587 20.321 82.6711 19.9615C81.4834 19.6016 80.244 19.4217 78.9534 19.4217C77.3374 19.4217 75.8788 19.757 74.5766 20.4272C73.2754 21.0974 72.2641 22.043 71.5426 23.2637C70.8216 24.484 70.4607 25.841 70.4607 27.334C70.4607 28.8598 70.8188 30.2276 71.5348 31.4371C72.2502 32.6467 73.2593 33.5812 74.5605 34.2407C75.8626 34.8998 77.3263 35.2295 78.9534 35.2295C80.2006 35.2295 81.3911 35.0496 82.5243 34.6902C83.6575 34.3303 85.087 33.6819 86.8115 32.7446V36.8969C85.3363 37.6709 83.9806 38.221 82.7445 38.548C81.5079 38.8749 80.0649 39.0382 78.4166 39.0382C76.0191 39.0382 73.8773 38.5591 71.9904 37.6C70.1029 36.6409 68.6194 35.2597 67.5402 33.456C66.461 31.6523 65.9214 29.677 65.9214 27.5302C65.9214 25.2307 66.4776 23.1654 67.5896 21.3344C68.7011 19.5038 70.2299 18.0925 72.1773 17.1009C74.1238 16.1093 76.3178 15.613 78.7582 15.613Z"
    fill="#2B3137"></path>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M99.1761 23.1819C99.7069 23.1819 100.231 23.302 100.746 23.5413C101.261 23.7811 101.86 24.1846 102.544 24.7514L101.112 28.2655C99.9515 27.394 99.0187 26.9581 98.3138 26.9581C97.6953 26.9581 97.0855 27.19 96.4832 27.6528C95.8812 28.1162 95.3984 28.7288 95.0352 29.4918C94.6719 30.2547 94.4905 31.0176 94.4905 31.7806V38.7444H90.488V23.4759H94.4905V27.3667H94.5551C95.2816 25.8955 96.0137 24.8302 96.7518 24.1707C97.4894 23.5116 98.2977 23.1819 99.1761 23.1819Z"
    fill="#2B3137"></path>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M114.047 29.4757C113.938 28.3645 113.602 27.4949 113.038 26.8683C112.474 26.2417 111.698 25.9287 110.711 25.9287C109.703 25.9287 108.892 26.2283 108.279 26.8275C107.666 27.4272 107.278 28.3098 107.116 29.4757H114.047ZM110.549 23.1819C112.783 23.1819 114.551 23.9012 115.853 25.3399C117.154 26.7786 117.805 28.7673 117.805 31.3066V31.9114H107.051C107.213 33.2735 107.721 34.3226 108.572 35.0582C109.423 35.7938 110.592 36.1615 112.078 36.1615C113.033 36.1615 113.952 35.9955 114.836 35.663C115.72 35.3309 116.634 34.8268 117.577 34.151V37.1425C115.798 38.4068 113.64 39.0384 111.102 39.0384C108.705 39.0384 106.747 38.314 105.229 36.8647C103.71 35.4153 102.951 33.4859 102.951 31.0775C102.951 28.7562 103.653 26.8602 105.058 25.3886C106.462 23.9175 108.293 23.1819 110.549 23.1819Z"
    fill="#2B3137"></path>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M123.971 34.6572C123.971 35.2128 124.147 35.6599 124.5 35.9976C124.852 36.3357 125.288 36.5045 125.809 36.5045C126.688 36.5045 127.566 36.1015 128.445 35.2949V31.2734C127.327 31.6551 126.452 32.0145 125.817 32.3522C125.182 32.6903 124.716 33.0335 124.418 33.3818C124.119 33.731 123.971 34.1559 123.971 34.6572ZM126.411 23.1819C128.364 23.1819 129.855 23.5988 130.885 24.4323C131.916 25.2662 132.431 26.4294 132.431 27.9228V35.393C132.431 35.9709 132.664 36.2594 133.131 36.2594C133.326 36.2594 133.599 36.175 133.952 36.0062C134.304 35.8369 134.6 35.644 134.838 35.4255V37.7472C133.439 38.608 132.159 39.0384 130.999 39.0384C130.283 39.0384 129.732 38.9183 129.347 38.6785C128.962 38.4392 128.694 38.0139 128.542 37.4036C127.013 38.4935 125.478 39.0384 123.938 39.0384C122.843 39.0384 121.902 38.6679 121.115 37.9267C120.328 37.1861 119.936 36.2979 119.936 35.2623C119.936 33.9437 120.326 32.9029 121.107 32.14C121.888 31.3771 123.255 30.7125 125.207 30.1457L128.445 29.1977V28.4946C128.445 27.0017 127.544 26.255 125.744 26.255C124.822 26.255 123.905 26.4674 122.995 26.8927C122.083 27.3175 121.253 27.9446 120.505 28.7724V25.1595C122.165 23.8414 124.133 23.1819 126.411 23.1819Z"
    fill="#2B3137"></path>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M141.201 19.4221H141.526V23.4761H146.732V26.5167H141.526V33.5622C141.526 34.2491 141.732 34.8047 142.144 35.2295C142.557 35.6548 143.12 35.8672 143.836 35.8672C144.769 35.8672 145.843 35.5296 147.057 34.8538V38.0739C145.615 38.7172 144.259 39.0386 142.99 39.0386C141.276 39.0386 139.94 38.5702 138.979 37.6329C138.02 36.6956 137.54 35.4257 137.54 33.8238V26.5167H135.165V25.8628L141.201 19.4221Z"
    fill="#2B3137"></path>
  <mask
    id="mask0"
    mask-type="alpha"
    maskUnits="userSpaceOnUse"
    x="0"
    y="0"
    width="318"
    height="53">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.899902 52.9997H317.1V0H0.899902V52.9997Z"
      fill="white"></path>
  </mask>
  <g mask="url(#mask0)">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M149.465 38.7445H153.468V23.4765H149.465V38.7445ZM151.467 16.4146C152.085 16.4146 152.616 16.6381 153.061 17.0847C153.506 17.5318 153.728 18.0656 153.728 18.6867C153.728 19.2863 153.508 19.8174 153.069 20.2807C152.63 20.744 152.096 20.9755 151.467 20.9755C150.859 20.9755 150.331 20.744 149.88 20.2807C149.43 19.8174 149.205 19.2863 149.205 18.6867C149.205 18.055 149.433 17.5179 149.889 17.0764C150.344 16.6353 150.87 16.4146 151.467 16.4146Z"
      fill="#2B3137"></path>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M161.001 31.0452C161.001 32.571 161.372 33.7889 162.115 34.6984C162.858 35.6089 163.853 36.0638 165.1 36.0638C166.402 36.0638 167.427 35.6279 168.175 34.7559C168.924 33.884 169.298 32.6364 169.298 31.0127C169.298 29.465 168.921 28.2689 168.167 27.4243C167.413 26.5798 166.391 26.1573 165.1 26.1573C163.885 26.1573 162.898 26.6044 162.139 27.4981C161.38 28.3913 161.001 29.574 161.001 31.0452ZM165.132 23.1819C166.716 23.1819 168.153 23.5144 169.444 24.179C170.735 24.8441 171.732 25.7759 172.437 26.9748C173.143 28.1737 173.495 29.5303 173.495 31.045C173.495 32.6251 173.156 34.0096 172.478 35.1973C171.8 36.3851 170.802 37.3224 169.485 38.0088C168.166 38.6952 166.716 39.0384 165.132 39.0384C163.538 39.0384 162.09 38.6952 160.788 38.0088C159.487 37.3224 158.497 36.3851 157.819 35.1973C157.142 34.0096 156.802 32.6251 156.802 31.045C156.802 29.5085 157.161 28.1435 157.876 26.9502C158.592 25.7569 159.59 24.8307 160.87 24.1712C162.15 23.5116 163.57 23.1819 165.132 23.1819Z"
      fill="#2B3137"></path>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M185.502 23.1819C187.129 23.1819 188.428 23.719 189.399 24.7922C190.369 25.8658 190.855 27.2795 190.855 29.034V38.7444H186.869V29.4431C186.869 28.451 186.633 27.6663 186.161 27.0889C185.689 26.5114 185.047 26.2225 184.233 26.2225C183.615 26.2225 183.034 26.4052 182.492 26.7702C181.95 27.1352 181.353 27.7428 180.703 28.5929V38.7444H176.7V23.4759H180.703V25.6177C181.44 24.7458 182.196 24.122 182.972 23.7459C183.747 23.3697 184.591 23.1819 185.502 23.1819Z"
      fill="#2B3137"></path>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M211.077 15.613C213.29 15.613 215.421 16.1691 217.471 17.2804V21.204C215.117 19.7765 213.029 19.0623 211.207 19.0623C210.101 19.0623 209.236 19.2775 208.612 19.7083C207.989 20.1387 207.677 20.7133 207.677 21.4327C207.677 22.2935 208.257 23.0894 209.418 23.8194C209.743 24.0373 210.996 24.6477 213.176 25.6504C215.042 26.533 216.387 27.5139 217.211 28.5927C218.035 29.6715 218.448 30.9687 218.448 32.4834C218.448 34.4448 217.745 36.0282 216.34 37.2322C214.935 38.4362 213.116 39.0382 210.882 39.0382C209.429 39.0382 208.179 38.8777 207.132 38.5563C206.085 38.2349 204.911 37.6816 203.609 36.8969V32.5488C206.039 34.5756 208.328 35.589 210.475 35.589C211.549 35.589 212.416 35.3548 213.078 34.8863C213.74 34.4179 214.071 33.7969 214.071 33.0228C214.071 31.9659 213.496 31.1046 212.346 30.44C211.392 29.9168 210.161 29.2902 208.653 28.5602C206.864 27.7426 205.527 26.789 204.643 25.6991C203.759 24.6097 203.317 23.3454 203.317 21.9067C203.317 19.9889 204.027 18.4603 205.448 17.3216C206.869 16.183 208.745 15.613 211.077 15.613Z"
      fill="#2B3137"></path>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M225.947 19.4221H226.273V23.4761H231.478V26.5167H226.273V33.5622C226.273 34.2491 226.478 34.8047 226.891 35.2295C227.303 35.6548 227.867 35.8672 228.583 35.8672C229.515 35.8672 230.589 35.5296 231.804 34.8538V38.0739C230.361 38.7172 229.006 39.0386 227.737 39.0386C226.023 39.0386 224.687 38.5702 223.726 37.6329C222.766 36.6956 222.286 35.4257 222.286 33.8238V26.5167H219.911V25.8628L225.947 19.4221Z"
      fill="#2B3137"></path>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M237.108 34.6572C237.108 35.2128 237.284 35.6599 237.637 35.9976C237.989 36.3357 238.426 36.5045 238.946 36.5045C239.825 36.5045 240.703 36.1015 241.582 35.2949V31.2734C240.465 31.6551 239.589 32.0145 238.954 32.3522C238.32 32.6903 237.854 33.0335 237.555 33.3818C237.257 33.731 237.108 34.1559 237.108 34.6572ZM239.548 23.1819C241.501 23.1819 242.992 23.5988 244.023 24.4323C245.053 25.2662 245.568 26.4294 245.568 27.9228V35.393C245.568 35.9709 245.802 36.2594 246.268 36.2594C246.464 36.2594 246.737 36.175 247.089 36.0062C247.442 35.8369 247.737 35.644 247.976 35.4255V37.7472C246.577 38.608 245.297 39.0384 244.137 39.0384C243.421 39.0384 242.87 38.9183 242.485 38.6785C242.1 38.4392 241.831 38.0139 241.68 37.4036C240.151 38.4935 238.616 39.0384 237.076 39.0384C235.98 39.0384 235.04 38.6679 234.253 37.9267C233.466 37.1861 233.074 36.2979 233.074 35.2623C233.074 33.9437 233.464 32.9029 234.245 32.14C235.026 31.3771 236.393 30.7125 238.345 30.1457L241.582 29.1977V28.4946C241.582 27.0017 240.682 26.255 238.882 26.255C237.96 26.255 237.043 26.4674 236.132 26.8927C235.221 27.3175 234.391 27.9446 233.643 28.7724V25.1595C235.302 23.8414 237.271 23.1819 239.548 23.1819Z"
      fill="#2B3137"></path>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M254.338 19.4221H254.663V23.4761H259.869V26.5167H254.663V33.5622C254.663 34.2491 254.869 34.8047 255.281 35.2295C255.694 35.6548 256.258 35.8672 256.974 35.8672C257.906 35.8672 258.98 35.5296 260.195 34.8538V38.0739C258.752 38.7172 257.396 39.0386 256.128 39.0386C254.414 39.0386 253.077 38.5702 252.117 37.6329C251.157 36.6956 250.677 35.4257 250.677 33.8238V26.5167H248.302V25.8628L254.338 19.4221Z"
      fill="#2B3137"></path>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M262.602 38.7445H266.605V23.4765H262.602V38.7445ZM264.604 16.4146C265.222 16.4146 265.753 16.6381 266.198 17.0847C266.643 17.5318 266.865 18.0656 266.865 18.6867C266.865 19.2863 266.646 19.8174 266.207 20.2807C265.767 20.744 265.233 20.9755 264.604 20.9755C263.997 20.9755 263.468 20.744 263.018 20.2807C262.568 19.8174 262.343 19.2863 262.343 18.6867C262.343 18.055 262.57 17.5179 263.026 17.0764C263.482 16.6353 264.007 16.4146 264.604 16.4146Z"
      fill="#2B3137"></path>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M274.138 31.0452C274.138 32.571 274.51 33.7889 275.252 34.6984C275.995 35.6089 276.991 36.0638 278.238 36.0638C279.539 36.0638 280.564 35.6279 281.313 34.7559C282.061 33.884 282.436 32.6364 282.436 31.0127C282.436 29.465 282.059 28.2689 281.304 27.4243C280.551 26.5798 279.529 26.1573 278.238 26.1573C277.023 26.1573 276.036 26.6044 275.277 27.4981C274.518 28.3913 274.138 29.574 274.138 31.0452ZM278.27 23.1819C279.854 23.1819 281.291 23.5144 282.582 24.179C283.872 24.8441 284.87 25.7759 285.575 26.9748C286.28 28.1737 286.633 29.5303 286.633 31.045C286.633 32.6251 286.293 34.0096 285.616 35.1973C284.938 36.3851 283.94 37.3224 282.622 38.0088C281.304 38.6952 279.854 39.0384 278.27 39.0384C276.676 39.0384 275.228 38.6952 273.926 38.0088C272.625 37.3224 271.635 36.3851 270.957 35.1973C270.279 34.0096 269.94 32.6251 269.94 31.045C269.94 29.5085 270.298 28.1435 271.014 26.9502C271.73 25.7569 272.727 24.8307 274.008 24.1712C275.287 23.5116 276.708 23.1819 278.27 23.1819Z"
      fill="#2B3137"></path>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M298.639 23.1819C300.266 23.1819 301.565 23.719 302.536 24.7922C303.506 25.8658 303.992 27.2795 303.992 29.034V38.7444H300.006V29.4431C300.006 28.451 299.77 27.6663 299.298 27.0889C298.826 26.5114 298.184 26.2225 297.37 26.2225C296.752 26.2225 296.171 26.4052 295.629 26.7702C295.087 27.1352 294.491 27.7428 293.84 28.5929V38.7444H289.837V23.4759H293.84V25.6177C294.577 24.7458 295.334 24.122 296.109 23.7459C296.884 23.3697 297.728 23.1819 298.639 23.1819Z"
      fill="#2B3137"></path>
  </g>
  <path
    d="M324.228 27.1475H326.671C327.785 27.1475 328.592 27.3068 329.094 27.6255C329.599 27.9406 329.851 28.4437 329.851 29.1348C329.851 29.6038 329.74 29.9888 329.518 30.2896C329.3 30.5903 329.008 30.7712 328.643 30.832V30.8857C329.14 30.9967 329.498 31.2044 329.717 31.5088C329.939 31.8132 330.05 32.2178 330.05 32.7227C330.05 33.4388 329.79 33.9974 329.271 34.3984C328.755 34.7995 328.054 35 327.166 35H324.228V27.1475ZM325.893 30.2573H326.859C327.311 30.2573 327.636 30.1875 327.837 30.0479C328.041 29.9082 328.143 29.6772 328.143 29.355C328.143 29.0542 328.032 28.8394 327.81 28.7104C327.592 28.578 327.244 28.5117 326.768 28.5117H325.893V30.2573ZM325.893 31.5786V33.625H326.978C327.436 33.625 327.774 33.5373 327.993 33.3618C328.211 33.1864 328.32 32.9178 328.32 32.5562C328.32 31.9045 327.855 31.5786 326.924 31.5786H325.893ZM336.141 35H331.618V27.1475H336.141V28.5117H333.283V30.2358H335.942V31.6001H333.283V33.625H336.141V35ZM340.813 35H339.148V28.5332H337.016V27.1475H342.946V28.5332H340.813V35ZM348.961 35L348.392 33.1309H345.529L344.96 35H343.166L345.938 27.1152H347.973L350.755 35H348.961ZM347.995 31.7344C347.468 30.0407 347.171 29.0828 347.103 28.8608C347.039 28.6388 346.992 28.4634 346.963 28.3345C346.845 28.7928 346.507 29.9261 345.948 31.7344H347.995Z"
    fill="#8C97A4"></path>
</svg>
