<!-- @component
  TODO: Component description
 -->
<script>
  import IsMobile from "../../utils/IsMobile.svelte";

  let isTouch;
</script>

<style lang="scss">
  @import "../../theme/bulma/theme.scss";

  .intro-text-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: $dark;
    color: $white;
    padding: {
      top: 10em;
      bottom: 10em;
      left: 1.5em;
      right: 1.5em;
    }
  }

  .title {
    font-family: $family-sans-serif;
    font-weight: $font-weight-thin;
    color: $white;
    line-height: 1.2em;
    text-align: center;
    & em {
      font-family: $family-sans-serif;
    }
    & strong {
      font-family: $family-sans-serif;
      font-weight: 700;
    }
    @include mobile {
      font-size: 9vw;
    }
    @include tablet-only {
      font-size: 3em;
    }
    @include desktop-only {
      font-size: 3em;
    }
    @include widescreen-only {
      font-size: 3em;
    }
    @include fullhd {
      font-size: 4em;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  p {
    max-width: 768px;
    margin: {
      top: 3em;
    }
    text-align: center;

    strong {
      color: $white;
    }
  }

  .quote-author {
    margin: {
      top: 3em;
    }
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      border-radius: 50%;
      width: 39px;
    }

    p {
      margin: 0;
      margin-top: 0.5rem;
      line-height: 1rem;
    }
  }
</style>

<div class="intro-text-section">
  <h1 class="title rellax" data-rellax-percentage="0.5" data-rellax-speed="1">
    No more chaos.
    {#if isTouch}
      <br />
    {/if}
    Say
    <em>hi</em>
    to
    <strong>focus.</strong>
  </h1>
  <p>
    Creation Station is your complete digital co-creation workstation. Collect
    data, research, come up with brilliant insights, ideate, make decisions and
    innovate. Creation Station makes it easy to collaborate remotely and manage
    Design Thinking and creation projects from start to finish.
  </p>
  <p>
    <em>
      "Creation Station takes the manual repetitive grunt work out of Design
      Thinking, so the team has more time to focus on what adds most value:
      analysis and ideation."
      <p><br>
        <strong>Strategist & Researcher</strong>
        </p>
    </em>
</div>

<IsMobile bind:isTouch />
